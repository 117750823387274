interface IProps {
  message?: string;
}

const PageLoader = ({message}: IProps) => {
  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      background: 'white',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }}>
      <img src="assets/icon/eye.svg" loading="lazy" alt="Mi Local" height="90" width="90"/>
      {
        message ? <div style={{fontWeight: 'bold', textAlign: 'center', marginTop: '3px'}}>{message}</div> : null
      }
    </div>
  );
};


export default PageLoader;
