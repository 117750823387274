import axios, {AxiosInstance} from 'axios';
import {getAuthFromCache} from './AuthenticationClient';
import {Preferences} from '@capacitor/preferences';
import Secrets from '@yumi/secrets';
import {ILink} from '../models/ILink';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJSON = require('../../../../package.json');

const LOCALIZATION = {
  ACCESS_DENIED: {
    TITLE: 'Access Denied',
    MESSAGE:
      'Your session has expired, and you need to login again (Access Denied)'
  }
};

const AppVersion = packageJSON.version

interface ICONFIG {
  baseURL: string;
}

export interface IArrayRestResponse<TType> {
  data: Array<TType>;
  data_v2?: Array<TType>;
  offset: number;
  limit: number;
  total: number;
  links?: Array<ILink>;
}

export default class RESTClient {
  protected axios: AxiosInstance;
  protected baseUrl: string;
  constructor(config: ICONFIG) {
    const {baseURL} = config;
    this.baseUrl = baseURL;
    this.axios = axios.create({baseURL});

    // Enable Mock for Axios
    /*
    Expr.whenTestMode(() => {
      const mocksAdapter = require('./../mocks').default;
      mocksAdapter(this.axios);
    })
    */

    this.axios.interceptors.request.use(
      async (config) => {

        if (!config.headers) {
          throw new Error('Config is not defined');
        }

        const auth = await getAuthFromCache();
        const jwt = auth.user;
        const allowedDomain = Secrets.REACT_APP_DOMAIN;
        config.headers['Authorization'] = `${jwt.token_type.toLowerCase()} ${
          jwt.access_token
        }`;
        config.headers['X-Frame-Options'] = 'SAMEORIGIN';
        config.headers['X-XSS-Protection'] = '1; mode=block';
        config.headers['X-Content-Type-Options'] = 'nosniff';
        config.headers['Strict-Transport-Security'] =
          'max-age=31536000; includeSubDomains';
        config.headers[
          'Content-Security-Policy'
        ] = `default-src 'self' ${allowedDomain}`;
        config.headers['App-Version'] = AppVersion;

        return config;
      },
      (error) => {
        throw error;
      }
    );

    // Add a 401 response interceptor
    this.axios.interceptors.response.use(
      (response) => response,
      function (error) {
        if (!error.response) {
          throw error;
        }

        switch (error.response.status) {
          case 401:
            Preferences.remove({key: '@user'});
            //localStorage.removeItem();
            // TODO: Hide the alert because in android could not reload the pag
            window.location.reload();
            throw error;
          case 403:
            throw error;
          default:
            throw error;
        }
      }
    );
  }
}
