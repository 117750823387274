import { EventEmitter } from 'events';

enum EVENTS_ENUM {
  // TASK AND FLOW EVENTS
  'SETTINGS_CHANGED',
  'SETTINGS_REMOVED',
  'NAVIGATE_TO',
  'DEEPLINK:SSO_CALLBACK',
  'DEEPLINK:NOTIFICATION_RECEIVED',
  'OPEN_CLOSE_REPORT',
  'OPEN_CLOSE_TASK_ERROR',
  'LOSSPLAN_TASK_ERROR',
  'SENSITIVE_PRODUCTS_REVIEW',
  'DC_VERIFICATION',
  'ERROR_NOTIFICATION',
  'RELOAD_HOME',
  'GO_TO_REQUEST_DETAILS',
  'RELOAD_TASKS',
  'EXPIRED_SESSION',
  'USER_ACTIVE_EVENT'
}
export type EventStreamerTypes = keyof typeof EVENTS_ENUM;

class EventStreamer {
  private static emitter = new EventEmitter();

  on(event: EventStreamerTypes | symbol, listener: (...args: any[]) => void) {
    EventStreamer.emitter.on(event, listener);
  }

  off(event: EventStreamerTypes | symbol, listener: (...args: any[]) => void) {
    EventStreamer.emitter.off(event, listener);
  }

  emit(event: EventStreamerTypes | symbol, ...args: any[]): boolean {
    return EventStreamer.emitter.emit(event, ...args);
  }
}

export default new EventStreamer();
