import RESTClient from './RESTClient';
import IUserStatus from '../models/users/IUserStatus';
import AuthenticationClient from './AuthenticationClient';
import {Device} from '@capacitor/device';
import {App} from '@capacitor/app';
import { isEmpty } from 'lodash';
import { isPlatform } from '@ionic/react';
import { AppVersion } from '../lib/utils';
import Secrets from '@yumi/secrets';

class UserStatusClient extends RESTClient {
  async getDataPingUserStatus(): Promise<IUserStatus> {
    const [deviceInfo, infoVersion]: any[] = await Promise.all([
      Device.getInfo(),
      isPlatform('capacitor') ?
        App.getInfo() :
        new Promise((resolve) => {
            resolve({});
          })
    ]);
    const {platform, model, operatingSystem, osVersion} = deviceInfo;
    const {businessUnit, primarysid, store, email, country} =
      AuthenticationClient.getInfo();
    return {
      store,
      email,
      businessUnit: businessUnit?.toUpperCase(),
      device: `${platform} - ${model} - ${operatingSystem} - ${osVersion}`,
      primaryId: primarysid,
      country: country?.toUpperCase(),
      version: isEmpty(infoVersion) ?
        `Web` :
        `base ${infoVersion.version}-${infoVersion.build}`
    } as IUserStatus;
  }

  async getAppData(): Promise<any> {
    const [deviceInfo, infoVersion]: any[] = await Promise.all([
      Device.getInfo(),
      isPlatform('capacitor') ?
        App.getInfo() :
        new Promise((resolve) => {
            resolve({});
          })
    ]);
    const {platform, model, operatingSystem, osVersion} = deviceInfo;

    return {
      "app-version": AppVersion,
      device: `${platform} - ${model} - ${operatingSystem} - ${osVersion}`,
      version: isEmpty(infoVersion) ?
        `Web` :
        `base ${infoVersion.version}-${infoVersion.build}`
    } ;
  }

  async pingStatus(): Promise<any> {
    try {
      const body: IUserStatus = await this.getDataPingUserStatus();
      const {data} = await this.axios.post(`/users/update-status`, body);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new UserStatusClient({
  baseURL: Secrets.REACT_APP_API!
});
