import { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorFallbackPage } from './ErrorFallbackPage';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}


export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  override componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, error });
    console.log(error, info.componentStack);
  }

  override render() {
    if (this.state.hasError) {
      return <ErrorFallbackPage />;
    }
    return this.props.children;
  }
}
