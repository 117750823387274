import TaskTypes from './TaskTypes';

export const BU_MDH = 'C004';
export const BU_SM = 'C001';

export const NOMENCLATORS = {
  NEWS_CATEGORIES: 'NEWS_CATEGORIES',
  TASK_AREAS: 'TASK_AREAS'
};

export const TASK_TYPES = {
  TASKMANAGER: 'TASKMANAGER',
  RACKREVIEW: 'RACKREVIEW',
  LOSSSTATEMENT: 'LOSSSTATEMENT',
  LOSSPLAN: 'LOSSPLAN',
  INTERNAL_CONSUMPTION: 'INTERNALCONSUMPTION',
  INTERNAL_CONSUMPTION_CONTROL: 'INTERNALCONSUMPTIONCONTROL'
};

export const Scopes = {
  // RACKREVIEW MANAGER AND SUB MANAGER
  RACKREVIEW_MANAGER: 'rackreview.manager'
}

export const TasksGetByScopes = [
  TaskTypes['STOCK-ADJUSTMENT-REQUEST']
]


export const ZIPPEDI_TASKS_TYPES: string[] = [
  TaskTypes['ZIPPEDI-LANDMARK'],
  TaskTypes['ZIPPEDI-PRICE-DIFFERENCE'],
  TaskTypes['ZIPPEDI-OUT-OF-STOCK'],
  TaskTypes['ZIPPEDI-PROMOTION']
]

export const DIGITAL_CHECKLIST_TASK_TYPE = 'CHECKLIST';
export const DIGITAL_CHECKLIST_ITEM_TASK_TYPE = 'CHECKLIST-ITEM';
