import React, { Suspense } from 'react';
import Ramen from '@team_yumi/ramen';
import PageLoader from './Fallback';

import { ErrorBoundary } from './ErrorBoundary';

const Legacy = React.lazy(() => import('./app'));
const { XConfigProvider } = Ramen;

const Lazy: React.FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<PageLoader />}>
        <XConfigProvider country="cl" theme="arcus">
          <Legacy />
        </XConfigProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Lazy;
