// THIS FILE IS MANAGED BY SEKI, ANY CHANGES MADE MAY BE LOST,
//,
// Find out how to modify this file through secrets configuration here: ,
// https://github.com/Cencosud-xlabs/Seki/blob/main/README.md,
export default {
  /** Seki product name */
  "PRODUCT_NAME": "fake",
  /** undefined */
  "REACT_APP_FIREBASE_ANALYTICS_APIKEY": "fake",
  /** undefined */
  "REACT_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN": "fake",
  /** undefined */
  "REACT_APP_FIREBASE_ANALYTICS_PROJECT_ID": "fake",
  /** undefined */
  "REACT_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET": "fake",
  /** undefined */
  "REACT_APP_FIREBASE_ANALYTICS_MESSAGGING_SENDER_ID": "fake",
  /** undefined */
  "REACT_APP_FIREBASE_ANALYTICS_APP_ID": "fake",
  /** undefined */
  "REACT_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID": "fake",
  /** undefined */
  "REACT_APP_FIREBASE_ANALYTICS_EMAIL": "fake",
  /** undefined */
  "REACT_APP_FIREBASE_ANALYTICS_PASSWORD": "fake",
  /** undefined */
  "REACT_APP_API_INJECTOR_URL": "fake",
  /** undefined */
  "REACT_APP_API_BOMBERO_ENDPOINT": "fake",
  /** undefined */
  "REACT_APP_IAM_API_ENDPOINT": "fake",
  /** undefined */
  "REACT_APP_COUNTRECOMMENDER_API_ENDPOINT": "fake",
  /** undefined */
  "REACT_APP_DOMAIN": "fake",
  /** undefined */
  "REACT_APP_USER_API_ENDPOINT": "fake",
  /** undefined */
  "REACT_APP_PORTAL_API": "fake",
  /** undefined */
  "REACT_APP_API": "fake",
  /** undefined */
  "REACT_APP_PLANOGRAMS_API_ENDPOINT": "fake",
  /** undefined */
  "REACT_APP_TICKETS_API_ENDPOINT": "fake",
  /** undefined */
  "REACT_APP_TICKETS_PROVISION_API_ENDPOINT": "fake",
  /** undefined */
  "REACT_APP_STOCK_NRT_ONLINE_API_ENDPOINT": "fake",
  /** undefined */
  "REACT_APP_ENVIRONMENT": "fake",
  /** undefined */
  "REACT_APP_EXCLUDEDCATEGORIES": "fake",
  /** undefined */
  "REACT_APP_NOTIFICATION_API": "fake",
  /** undefined */
  "REACT_APP_NEWS_API": "fake",
  /** undefined */
  "REACT_APP_MONGO_STORES_API": "fake",
  /** undefined */
  "REACT_APP_TASKS_API": "fake",
  /** undefined */
  "PUBLIC_URL": "fake",
  /** undefined */
  "REACT_APP_HIDE_USER_LOGIN_BUTTON": "fake",
  /** undefined */
  "REACT_APP_TASKS_GENERATOR_API": "fake",
  /** undefined */
  "REACT_APP_PRODUCT_IMAGES_API": "fake",
  /** List of stores with locations */
  "REACT_APP_SM_STORES_WITH_LOCATIONS": "fake",
  /** undefined */
  "MERMA_BFF_URL": "fake",
  /** undefined */
  "MERMA_INVENTORY_URL": "fake",
  /** yumi-digital-checklist BFF api */
  "DIGITAL_CHECKLIST_API_URL": "fake",
  /** undefined */
  "YUMI_PRINTER_BFF_URL": "fake",
  /** undefined */
  "REACT_APP_DEEP_LINK_URL": "fake",
  /** undefined */
  "REACT_APP_TOKEN_API_ENDPOINT": "fake",
  /** undefined */
  "REACT_APP_SKELETON_WAIT_DELAY": "fake",
  /** URL to Store Cashiers API GraphQL */
  "REACT_STORE_CASHIERS_API_URL": "fake",
  /** Arcus Pactolus API Endpoint */
  "ARCUS_PACTOLUS_API": "fake",
  /** Arcus Asistance API URL */
  "REACT_ASSISTANCE_API_URL": "fake",
}