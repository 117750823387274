enum TaskTypes {
  STOCKADJUSTMENTREQUEST = 'STOCKADJUSTMENTREQUEST',
  OTHERREASONS = 'OTHERREASONS',
  EXTERNALREQUESTS = 'EXTERNALREQUESTS',
  FOUNDRATEALERTS = 'FOUNDRATEALERTS',
  FORECASTALERTS = 'FORECASTALERTS',
  PLANOGRAMS = 'PLANOGRAMS',
  TASKMANAGER = 'TASKMANAGER',
  'ZIPPEDI-PRICE-DIFFERENCE' = 'ZIPPEDI-PRICE-DIFFERENCE',
  'ZIPPEDI-OUT-OF-STOCK' = 'ZIPPEDI-OUT-OF-STOCK',
  'ZIPPEDI-LANDMARK' = 'ZIPPEDI-LANDMARK',
  INACTIVE_LANDMARK = 'INACTIVE-LANDMARK',
  MISSING_LANDMARK = 'MISSING-LANDMARK',
  'ZIPPEDI-PROMOTION' = 'ZIPPEDI-PROMOTION',
  INACTIVE_PROMOTION = 'INACTIVE-PROMOTION',
  MISSING_PROMOTION = 'MISSING-PROMOTION',
  'STOCK-ADJUSTMENT-REQUEST' = 'STOCK-ADJUSTMENT-REQUEST',
  USER_ACCESS_REQUEST = 'USER_ACCESS_REQUEST',
  FOLLOWUP = 'FOLLOWUP',
  'PROMOTION-EVENT' = 'PROMOTION-EVENT',
}

export default TaskTypes;
